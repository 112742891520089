<template>
    <div class="payFail">
        <div class="navBar">
            <div @click="backPage">
                <svg-icon class="navArrow" iconClass="navArrow" />
            </div>
            <source>
            <div class="navTitle">问题详情</div>
            <div class="rightBtn"></div>
        </div>
        <div class="main">
            <div class="title">通常会有哪些原因造成支付失败？</div>
            <div class="content">
                网络故障等原因，会提示您“支付失败，请重试”，在这种情况下，您不会被扣费，该情况下，可能是短暂的网络不稳定，请您重新进入购买页面进行支付。
            </div>
            <div class="content">
                由于您微信、支付宝等支付方式余额不足，在这种情况下，您不会被扣费，该情况下您完成充值后再进行支付。
            </div>
            <div class="content">
                还有一种原因是支付通道不稳定，导致订单支付失败，可尝试重新发起订单付款请求，后台会切换支付通道保持支付流畅。
            </div>
            <div class="content">
                如果您确定已经扣款，可前往会员支付页面，点击右上角“账单明细”在订单列表里面查看消费记录，若无消费记录，请您咨询在线客服。
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            // 返回
            backPage() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .payFail {
        height: 100%;
        color: #000;
        background: #f5f5f5;

        .navBar {
            height: 44px;
            padding: 0 12px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;

            .navArrow {
                width: 17px;
                height: 17px;
            }

            .navTitle {
                flex: 2;
                text-align: center;
                font-size: 18px;
                
            }

            .rightBtn {
                width: 17px;
                height: 17px;
            }
        }
        .main {
            height: calc(100% - 45px);
            overflow-y: auto;
            padding: 0 12px;
            box-sizing: border-box;
            font-size: 14px;
            .title {
                font-weight:600;
                margin: 12px 0;
            }
            .content {
                line-height: 26px;
                margin-bottom: 26px;
                color: #333;
            }
        }
    }
</style>